import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import logo from '../images/logo.png';
import logoWhite from '../images/logo_white.png';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen font-sans bg-gray-50">
      {/* Header */}
      <header className="w-full bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <img src={logo} alt="Kapimej" width={50} height={50} />
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Home
            </Link>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">
          Privacy Policy
        </h1>
        <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
          <h1>Kapimej Privacy Policy</h1>
          <p><strong>Effective Date:</strong> 22 October, 2024</p>
          <p>Kapimej values your privacy and is committed to protecting your personal data. This privacy policy explains how we collect, use, and protect your information when you use our services.</p>

          <h2>What Data Do We Collect?</h2>
          <p>Kapimej collects the following data:</p>
          <ul>
            <li><strong>Personal Identification Information:</strong> (e.g., name, email address)</li>
            <li><strong>Public Social Media Profile Information:</strong> If you create an account using Google or Apple, we may collect your public profile avatar.</li>
          </ul>

          <h2>How Do We Collect Your Data?</h2>
          <p>We collect data directly from you when you:</p>
          <ul>
            <li>Register for an account or place an order for our services.</li>
            <li>Complete a customer survey or provide feedback via email.</li>
          </ul>
          <p>Kapimej may also receive data indirectly from the following sources:</p>
          <ul>
            <li>Google and Apple (if you create an account using these methods)</li>
          </ul>

          <h2>How Will We Use Your Data?</h2>
          <p>Kapimej uses your data to:</p>
          <ul>
            <li>Process your orders and manage your account.</li>
            <li>Provide customer support.</li>
            <li>Email you with information about products and services that may interest you.</li>
          </ul>
          <p>If you agree, Kapimej may share your data with our partner companies for marketing purposes.</p>

          <h2>How Do We Store Your Data?</h2>
          <p>Kapimej securely stores your data on servers provided by reputable cloud computing providers located in [Insert Locations]. Your data is encrypted both at rest and in transit.</p>
          <p>We will store your account, profile, order history, and similar data until you choose to delete it. Data related to your purchases will be stored for a minimum of 5 years to comply with legal requirements.</p>

          <h2>Marketing</h2>
          <p>Kapimej would like to send you information about products and services that we believe may interest you. If you agree to receive marketing communications, you can opt out at any time by sending an email to <a href="mailto:support@kapimej.com">support@kapimej.com</a>, and we will act upon your request within 5 working days.</p>

          <h2>Your Data Protection Rights</h2>
          <p>Kapimej wants to ensure you are aware of your data protection rights. You are entitled to the following:</p>
          <ul>
            <li><strong>The Right to Access:</strong> Request copies of your personal data.</li>
            <li><strong>The Right to Rectification:</strong> Request correction of any inaccurate information.</li>
            <li><strong>The Right to Erasure:</strong> Request the deletion of your personal data under certain conditions.</li>
            <li><strong>The Right to Restrict Processing:</strong> Request the restriction of processing your personal data under certain conditions.</li>
            <li><strong>The Right to Object to Processing:</strong> Object to Kapimej's processing of your personal data under certain conditions.</li>
            <li><strong>The Right to Data Portability:</strong> Request the transfer of your data to another organization, or directly to you, under certain conditions.</li>
          </ul>
          <p>If you make a request, we have one month to respond. For any of these rights, please contact us at <a href="mailto:support@kapimej.com">support@kapimej.com</a>.</p>

          <h2>Changes to Our Privacy Policy</h2>
          <p>Kapimej reviews its privacy policy regularly and updates this page as necessary. This privacy policy was last updated on 22 October 2024.</p>

          <h2>How to Contact Us</h2>
          <p>If you have any questions about our privacy policy, the data we hold about you, or if you would like to exercise your data protection rights, please contact us at <a href="mailto:support@kapimej.com">support@kapimej.com</a>.</p>
          <p>Phone: +37477691660</p>
          <p>Address: Aram Street 4/3, 40 apt</p>
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full bg-gray-900 text-white py-8 mt-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center space-y-4">
            <img src={logoWhite} alt="Kapimej" className="h-32 w-auto" />
            <nav className="flex space-x-4">
              <Link to="/" className="text-gray-400 hover:text-blue-600 transition-colors">
                Home
              </Link>
              <Link to="/about" className="text-gray-400 hover:text-blue-600 transition-colors">
                About Us
              </Link>
              <Link to="/terms-of-service" className="text-gray-400 hover:text-blue-600 transition-colors">
                Terms of Service
              </Link>
            </nav>
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Kapimej. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
