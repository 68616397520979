import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import logo from '../images/logo.png';
import logoWhite from '../images/logo_white.png';

export default function TermsOfService() {
  return (
    <div className="min-h-screen font-sans bg-gray-50">
      <header className="w-full bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <img src={logo} alt="Kapimej" width={50} height={50} />
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Home
            </Link>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
          <h1>TERMS OF SERVICE</h1>
          <p><strong>Last updated: October 22, 2024</strong></p>

          <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
          <p>Welcome to Kapimej LLC ("Company," "we," "us," "our"). We operate the Kapimej mobile application (the "App") and related services (collectively, the "Services"). By accessing or using our Services, you agree to these Terms of Service. If you do not agree, please discontinue your use immediately.</p>

          <h2>1. OUR SERVICES</h2>
          <p>Our Services are intended for individuals aged 18 and older. You are solely responsible for complying with any local laws applicable to your use of the Services.</p>

          <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
          <p>All intellectual property rights associated with our Services, including content and trademarks, are owned by us. The content provided is intended for your personal, non-commercial use only. Reproduction or distribution of any part of the Services without our express written permission is prohibited.</p>

          <h2>3. USER REPRESENTATIONS</h2>
          <p>By using the Services, you represent that your registration information is accurate and complete, and you agree not to use the Services for any illegal purposes.</p>

          <h2>4. USER REGISTRATION</h2>
          <p>You may need to register to access certain features of the Services. You are responsible for maintaining the confidentiality of your password and account information.</p>

          <h2>5. PRODUCTS</h2>
          <p>All products are subject to availability. Prices are subject to change, and we reserve the right to discontinue products at any time.</p>

          <h2>6. PURCHASES AND PAYMENT</h2>
          <p>We accept Visa, Mastercard, and ArCa. By making a purchase, you agree to provide accurate payment information and are responsible for any charges incurred.</p>

          <h2>7. REFUNDS POLICY</h2>
          <p>Due to the nature of eSIM products, all sales are final. We do not accept returns or exchanges for activated eSIMs.</p>
          <p>If you experience any issues with your eSIM that prevent it from functioning as intended, please contact our support team at <a href="mailto:support@kapimej.app">support@kapimej.app</a> within 14 days of purchase. We will review your case and may issue a replacement or refund at our discretion.</p>
          
          <p>To be eligible for a refund or replacement:</p>
          <ul>
            <li>You must contact us within 14 days of the eSIM purchase.</li>
            <li>You must provide evidence of the issue (e.g., screenshots, error messages).</li>
            <li>The eSIM must not have been activated or used, unless it is defective.</li>
          </ul>
          
          <p>If your request for a refund is approved, we will process your refund, and a credit will be automatically applied to your original payment method within a timeframe determined by your payment provider's policies.</p>

          <h2>8. PROHIBITED ACTIVITIES</h2>
          <p>You agree not to use the Services for any illegal activities or in any manner inconsistent with these Terms of Service. Prohibited activities include, but are not limited to:</p>
          <ul>
            <li>Misusing eSIM services</li>
            <li>Unauthorized sharing of eSIM credentials</li>
            <li>Excessive use of support services</li>
            <li>Collecting user data for unsolicited emails</li>
            <li>Competing with us using our Services</li>
          </ul>
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full bg-gray-900 text-white py-8 mt-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center space-y-4">
            <img src={logoWhite} alt="Kapimej" className="h-32 w-auto" />
            <nav className="flex space-x-4">
              <Link to="/" className="text-gray-400 hover:text-blue-600 transition-colors">
                Home
              </Link>
              <Link to="/about" className="text-gray-400 hover:text-blue-600 transition-colors">
                About Us
              </Link>
              <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-600 transition-colors">
                Privacy Policy
              </Link>
            </nav>
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Kapimej. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
