import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Search, ChevronRight, Globe, Zap, Shield, ChevronDown, ChevronUp, X } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import logo from '../images/logo.png';
import logoWhite from '../images/logo_white.png';
import appInterface from '../images/interface.png';
import appStore from '../images/app_store.png';
import googlePlay from '../images/google_play.png';

export default function Home() {
  const [searchQuery, setSearchQuery] = useState('')
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const searchRef = useRef<HTMLDivElement>(null)

  const destinations = [
    { name: 'United States', flag: '🇺🇸', code: 'US' },
    { name: 'Japan', flag: '🇯🇵', code: 'JP' },
    { name: 'France', flag: '🇫🇷', code: 'FR' },
    { name: 'Australia', flag: '🇦🇺', code: 'AU' },
    { name: 'Brazil', flag: '🇧🇷', code: 'BR' },
    { name: 'Canada', flag: '🇨🇦', code: 'CA' },
    { name: 'Germany', flag: '🇩🇪', code: 'DE' },
    { name: 'Italy', flag: '🇮🇹', code: 'IT' },
    { name: 'Spain', flag: '🇪🇸', code: 'ES' },
  ]

  const filteredDestinations = destinations.filter(destination =>
    destination.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const features = [
    { icon: <Globe className="w-8 h-8" />, title: 'Global Coverage', description: 'Stay connected in over 190 countries' },
    { icon: <Zap className="w-8 h-8" />, title: 'Instant Activation', description: 'Get online within minutes of purchase' },
    { icon: <Shield className="w-8 h-8" />, title: 'Secure Connection', description: 'Your data is always protected' },
  ]

  const steps = [
    {
      number: '01',
      title: 'Choose your eSIM plan',
      description: 'Select a data plan that fits your travel needs.',
      image: '/placeholder.svg?height=160&width=160'
    },
    {
      number: '02',
      title: 'Install Kapimej app',
      description: 'Download our app and follow the setup instructions.',
      image: '/placeholder.svg?height=160&width=160'
    },
    {
      number: '03',
      title: 'Enjoy seamless connectivity',
      description: 'Your eSIM activates automatically upon arrival.',
      image: '/placeholder.svg?height=160&width=160'
    }
  ]

  const faqs = [
    {
      question: 'What is an eSIM?',
      answer: 'An eSIM (embedded SIM) is a digital SIM that allows you to activate a cellular plan from a carrier without having to use a physical SIM card.'
    },
    {
      question: 'How do I know if my device is eSIM compatible?',
      answer: 'Most modern smartphones support eSIM. You can check your device settings or consult your device manufacturers website for eSIM compatibility information.'
    },
    {
      question: 'Can I use Kapimej eSIM alongside my regular SIM card?',
      answer: 'Yes, most eSIM-compatible devices allow you to use both a physical SIM and an eSIM simultaneously, enabling you to have two active phone numbers on one device.'
    },
    {
      question: 'How long does it take to activate a Kapimej eSIM?',
      answer: 'Kapimej eSIM activation is usually instant. Once you purchase a plan and scan the QR code, you can be connected within minutes.'
    }
  ]

  return (
    <div className="min-h-screen font-sans">
      {/* Header */}
      <header className="w-full bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              <img src={logo} alt="Kapimej" className="h-16 w-auto" />
            </Link>
            <nav className="hidden md:flex space-x-1">
              {['Features', 'Destinations', 'How It Works', 'FAQ'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors"
                >
                  {item}
                </a>
              ))}
            </nav>
            <div className="flex items-center space-x-4">
              <button
                className="px-4 py-2 rounded-md bg-blue-600 text-white text-sm hover:bg-blue-700 transition-colors"
                onClick={() => setIsModalOpen(true)}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="w-full px-4 py-12 md:py-24 bg-gradient-to-r from-blue-100 to-purple-100">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h1 className="text-4xl md:text-6xl font-bold leading-tight text-gray-900">
                Travel the world with Kapimej eSIM
              </h1>
              <p className="text-xl text-gray-600">
                Stay connected globally with our easy-to-use eSIM technology. No more hunting for local SIM cards or dealing with roaming fees.
              </p>
              <div className="relative max-w-md" ref={searchRef}>
                <input
                  type="text"
                  placeholder="Where are you traveling to?"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                    setShowSuggestions(true)
                  }}
                  onFocus={() => setShowSuggestions(true)}
                  className="w-full px-4 py-3 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <AnimatePresence>
                  {showSuggestions && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute z-10 w-full mt-2 bg-white rounded-lg shadow-lg overflow-hidden"
                    >
                      {filteredDestinations.length > 0 ? (
                        filteredDestinations.map((destination) => (
                          <button
                            key={destination.code}
                            className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100 transition-colors"
                            onClick={() => {
                              setSearchQuery(destination.name)
                              setShowSuggestions(false)
                              setIsModalOpen(true)
                            }}
                          >
                            <span className="mr-2">{destination.flag}</span>
                            <span>{destination.name}</span>
                          </button>
                        ))
                      ) : (
                        <div className="px-4 py-2 text-gray-500">
                          No destinations found
                        </div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className="relative">
              <img
                src={appInterface}
                alt="Kapimej App Interface"
                className="mx-auto rounded-3xl w-72 h-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="w-full bg-gray-50 py-12 md:py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose Kapimej?
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                  <div className="text-blue-600">{feature.icon}</div>
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Destinations Section */}
      <section id="destinations" className="w-full bg-white py-12 md:py-24">
        <div className="container mx-auto px-4">
          <div className="space-y-8">
            <h2 className="text-3xl font-bold">
              Popular Destinations
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {destinations.map((destination) => (
                <motion.button
                  key={destination.code}
                  className="flex items-center justify-between p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl hover:shadow-lg transition-all"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setIsModalOpen(true)}
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-2xl">{destination.flag}</span>
                    <span className="font-medium">{destination.name}</span>
                  </div>
                  <ChevronRight className="text-blue-600" />
                </motion.button>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section id="how-it-works" className="w-full bg-white py-12 md:py-24">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              How Kapimej eSIM Works
            </h2>
            <p className="text-xl text-gray-600">
              Get connected in three simple steps
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                  <span className="text-2xl font-bold text-blue-600">{step.number}</span>
                </div>
                <h3 className="text-xl font-bold mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-600">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="w-full bg-gray-100 py-12 md:py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <button
                  className="flex justify-between items-center w-full px-6 py-4 text-left"
                  onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}
                >
                  <span className="text-lg font-semibold">
                    {faq.question}
                  </span>
                  {activeAccordion === index ? (
                    <ChevronUp className="w-5 h-5 text-blue-600" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  )}
                </button>
                <AnimatePresence>
                  {activeAccordion === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden bg-gray-50"
                    >
                      <p className="px-6 py-4 text-gray-600">
                        {faq.answer}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-12 md:py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Stay Connected Worldwide?
          </h2>
          <p className="text-xl mb-8">
            Download the Kapimej app now and experience hassle-free global connectivity.
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <button
              className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors"
              onClick={() => setIsModalOpen(true)}
            >
              Download on App Store
            </button>
            <button
              className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors"
              onClick={() => setIsModalOpen(true)}
            >
              Get it on Google Play
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="w-full bg-gray-900 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center space-y-4">
            <div className="text-2xl font-bold text-blue-600">
              <img src={logoWhite} alt="Kapimej" className="h-32 w-32" />
            </div>
            <nav className="flex space-x-4">
              <Link to="/about" className="text-gray-400 hover:text-blue-600 transition-colors">
                About Us
              </Link>
              <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-600 transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-gray-400 hover:text-blue-600 transition-colors">
                Terms of Service
              </Link>
            </nav>
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Kapimej. All rights reserved.
            </div>
          </div>
        </div>
      </footer>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                Download Our App
              </h2>
              <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X className="w-6 h-6" />
              </button>
            </div>
            <p className="mb-6">
              To continue, please download our app from the App Store or Google Play.
            </p>
            <div className="flex flex-col space-y-4">
              <button className="text-white px-6 py-3 rounded-lg font-semibold  transition-colors flex items-center justify-center">
                <img src={appStore} alt="App Store" className="mr-2" />
              </button>
              <button className=" text-white px-6 py-3 rounded-lg font-semibold  transition-colors flex items-center justify-center">
                <img src={googlePlay} alt="Google Play" className="mr-2" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}