import { useState } from 'react'

export default function FAQPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [openItems, setOpenItems] = useState<number[]>([])

  const faqItems = [
    {
      question: "What is Kapimej?",
      answer: "Kapimej is a global eSIM service that provides seamless connectivity for travelers and digital nomads. Our platform allows you to instantly activate mobile data plans in over 190 countries without the need for physical SIM cards."
    },
    {
      question: "How does an eSIM work?",
      answer: "An eSIM (embedded SIM) is a digital SIM that allows you to activate a cellular plan from your carrier without using a physical SIM card. It's built into your device and can be programmed remotely, making it easy to switch between different mobile networks."
    },
    {
      question: "Is my device compatible with Kapimej?",
      answer: "Most modern smartphones and tablets support eSIM technology. You can check your device's compatibility in your phone settings or on our website. We support a wide range of devices from manufacturers like Apple, Samsung, Google, and more."
    },
    {
      question: "How do I activate my Kapimej eSIM?",
      answer: "Activating your Kapimej eSIM is simple: 1) Download the Kapimej app, 2) Choose your desired plan, 3) Complete the purchase, and 4) Follow the in-app instructions to scan the QR code and activate your eSIM. The process typically takes less than 5 minutes."
    },
    {
      question: "Can I use Kapimej alongside my regular SIM card?",
      answer: "Yes! Kapimej's eSIM can be used simultaneously with your physical SIM card. This allows you to keep your local number active while using Kapimej for data connectivity abroad."
    },
    {
      question: "What happens if I need customer support while traveling?",
      answer: "Our customer support team is available 24/7 via in-app chat, email, and phone. We offer support in multiple languages to ensure you can get help whenever and wherever you need it."
    },
    {
      question: "Are there any long-term contracts or commitments?",
      answer: "No, Kapimej offers flexible plans without any long-term contracts. You can choose from daily, weekly, or monthly plans based on your travel needs. Our plans are prepaid, so there are no surprise bills or hidden fees."
    },
    {
      question: "How secure is Kapimej's eSIM technology?",
      answer: "Kapimej uses state-of-the-art encryption and security protocols to protect your data and privacy. Our eSIM technology is as secure as traditional SIM cards, if not more so, as it cannot be physically removed or stolen."
    },
    {
      question: "Can I share my Kapimej data plan with other devices?",
      answer: "Yes, many of our plans allow data sharing or tethering. You can use your Kapimej-connected device as a mobile hotspot to provide internet access to your other devices, such as laptops or tablets."
    },
    {
      question: "What happens to my unused data?",
      answer: "Depending on the plan you choose, unused data may roll over to the next billing cycle or expire at the end of your current plan. Check the specific details of your chosen plan in the app for more information."
    }
  ]

  const toggleItem = (index: number) => {
    setOpenItems(prevOpenItems =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter(item => item !== index)
        : [...prevOpenItems, index]
    )
  }

  const filteredFAQs = faqItems.filter(item =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
          Frequently Asked Questions
        </h1>
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="space-y-4">
          {filteredFAQs.map((item, index) => (
            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
              <button
                className="w-full px-4 py-5 sm:p-6 text-left flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => toggleItem(index)}
              >
                <span className="text-lg font-medium text-gray-900">{item.question}</span>
                <svg
                  className={`h-5 w-5 text-gray-500 transform ${openItems.includes(index) ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              {openItems.includes(index) && (
                <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p className="text-gray-700">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        {filteredFAQs.length === 0 && (
          <p className="text-center text-gray-500 mt-8">
            No FAQs found matching your search term. Please try a different query.
          </p>
        )}
      </div>
    </div>
  )
}