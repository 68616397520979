import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft, Globe, Users, Shield } from 'lucide-react'
import logo from '../images/logo.png';
import logoWhite from '../images/logo_white.png';

export default function About() {
  return (
    <div className="min-h-screen font-sans">
      {/* Header */}
      <header className="w-full bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              <img src={logo} alt="Kapimej" className="h-16 w-auto" />
            </Link>
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Home
            </Link>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="w-full px-4 py-12 md:py-24 bg-gradient-to-r from-blue-100 to-purple-100">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold leading-tight text-gray-900 mb-4">
            About Kapimej
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Revolutionizing global connectivity with innovative eSIM solutions
          </p>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="w-full bg-white py-12 md:py-24">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-4">
                Our Story
              </h2>
              <p className="text-gray-600 mb-4">
                Kapimej was born from a simple idea: to make global connectivity effortless and affordable. As a solo entrepreneur with a passion for technology and travel, I saw an opportunity to revolutionize how people stay connected across borders.
              </p>
              <p className="text-gray-600">
                What started as a personal project has grown into a mission to provide seamless eSIM solutions for travelers, digital nomads, and global citizens in over 100 countries.
              </p>
            </div>
            <div className="relative h-64 md:h-full">
              {/* <img
                src="/placeholder.svg?height=400&width=600"
                alt="Kapimej Team"
                className="w-full h-full object-cover rounded-lg shadow-lg"
              /> */}
            </div>
          </div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="w-full bg-gray-50 py-12 md:py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Our Values
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Globe className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Global Accessibility
              </h3>
              <p className="text-gray-600">
                We believe that staying connected should be easy and affordable for everyone, everywhere.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Users className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Customer-Centric
              </h3>
              <p className="text-gray-600">
                Our customers are at the heart of everything we do. We're committed to providing exceptional service and support.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Shield className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Innovation
              </h3>
              <p className="text-gray-600">
                We're constantly pushing the boundaries of what's possible in mobile connectivity.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-12 md:py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Experience Seamless Connectivity?
          </h2>
          <p className="text-xl mb-8">
            Join the growing community of Kapimej users and enjoy hassle-free global communication.
          </p>
          <Link
            to="/"
            className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors inline-block"
          >
            Get Your eSIM Now
          </Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="w-full bg-gray-900 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center space-y-4">
            <div className="text-2xl font-bold text-blue-600">
              <img src={logoWhite} alt="Kapimej" className="h-32 w-32"  />
            </div>
            <nav className="flex space-x-4">
              <Link to="/" className="text-gray-400 hover:text-blue-600 transition-colors">
                Home
              </Link>
              <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-600 transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-gray-400 hover:text-blue-600 transition-colors">
                Terms of Service
              </Link>
            </nav>
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Kapimej. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}